import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p"><em parentName="strong">{`Happy New Year! Our free partner wod is today at 9:00 & 10:15am.`}</em></strong></p>
    <p>{`1-Partner works, 1-Partner rests.\\
2 rounds for time:\\
20-Calorie Bike`}</p>
    <p>{`22-Calorie Row`}</p>
    <p>{`20-Wall Balls (20/14)`}</p>
    <p>{`22-Burpees`}</p>
    <p>{`20-V Ups`}</p>
    <p>{`22-Sit-ups`}</p>
    <p>{`20-Calorie Row`}</p>
    <p>{`22-Calorie Bike`}</p>
    <p>{`20-Pull-ups`}</p>
    <p>{`22-KBS’s (53/35)`}</p>
    <p>{`20-Jump Squats`}</p>
    <p>{`22-Push-ups`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      